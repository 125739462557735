<template>
  <div class="box-summary-payment">
    <data-summary-payment
      v-if="dataResponse != {}"
      :data-response="dataResponse"
      :products="products"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/Payment/DataSummaryPayment.vue'
import { mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  name: 'OrderDetailBancolombia',
  components: { DataSummaryPayment },
  data() {
    return {
      dataResponse: {},
      products: [],
    }
  },
  computed: {
    getDataAnalytics() {
      let arrayTemp = []
      this.products.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.products.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  async mounted() {
    this.changeMenu({ menu: 'menu-simple' })
    this.changeFooter({ footer: 'footer-simple' })
    this.$store.state.loaderDerek = true
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/payment/bancolombia/response' + location.search,
    })
      .then(response => {
        if (response.data.response.carrito_cerrado == 0) {
          if (response.data.response.checkoutAnonimo == 1) {
            this.$router
              .replace({
                name: 'PaymentAnonymous',
                params: {
                  tokenCarrito: response.data.response.carrito.token,
                  showmodalPaymentDecline: true,
                },
              })
              .catch(() => {})
          } else {
            this.$router
              .replace({
                name: 'Payment',
                params: { showmodalPaymentDecline: true },
              })
              .catch(() => {})
          }
        } else {
          sendFBQEvent(this, 'Purchase', {
            value: response.data.response.total_payu,
            currency: 'COP',
            contents: response.data.response.productos_purchase,
            content_ids: response.data.response.productos_purchase.map(
              e => e.id,
            ),
            event_id: this.dataSummaryPayment.pedido.pedido
              .pedido_reference_sale,
            content_type: 'product',
          })
          localStorage.removeItem('cartTokken')
          this.dataResponse = response.data.response.pedido
          this.products = response.data.response.pedido.productos
          this.$store.state.loaderDerek = false
        }
      })
      .catch(() => {
        this.$store.state.loaderDerek = false
        this.$router.replace({ name: 'Home' }).catch(() => {})
      })
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    ...mapActions('layouts', ['changeMenu', 'changeFooter']),
  },
}
</script>
<style>
.box-summary-payment {
  background-color: #f4f6fb;
  padding: 3rem;
  width: 100%;
}
@media (max-width: 768px) {
  .box-summary-payment {
    padding: 3rem 15px;
  }
}
</style>
