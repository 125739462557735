<template>
  <div id="payment" class="pt-4">
    <div
      v-if="dataPayment.valor_total"
      style="background-color: white;"
      class="col-11 col-md-10 mx-auto mt-3"
    >
      <div class="container-resumen-transaccion">
        <div
          v-if="dataPayment.status_id == 1"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #C6ECBB;">
            <img src="@/assets/img/estado-success.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción exitosa</b>
          </h3>
          <p class="w-100" style="text-align: center">
            Se ha registrado tu pago
          </p>
        </div>
        <div
          v-if="dataPayment.status_id == 2"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #FFC8C8;">
            <img src="@/assets/img/estado-error.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción rechazada</b>
          </h3>
          <p class="w-100" style="text-align: center">
            No se ha podido validar tu pago
          </p>
        </div>
        <div
          v-if="dataPayment.status_id == 0"
          class="mx-auto mb-4 d-flex flex-wrap"
        >
          <div class="estado-transaccion" style="background-color: #FFE7CB;">
            <img src="@/assets/img/estado-alert.svg" width="33" />
          </div>
          <h3 class="w-100" style="text-align: center">
            <b>Transacción Pendiente</b>
          </h3>
          <p class="w-100" style="text-align: center">
            La transacción esta en proceso
          </p>
        </div>

        <div class="w-100 d-flex flex-wrap">
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la venta</b> <br />
              {{ dataPayment.referencia }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Referencia de la transacción</b> <br />
              {{ dataPayment.transaccion_id }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Fecha</b> <br />
              {{ dataPayment.fecha }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Valor total</b> <br />
              {{ dataPayment.valor_total }} {{ dataPayment.moneda }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Descripción</b> <br />
              {{ dataPayment.descripcion }}
            </p>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 item-transaccion">
            <p>
              <b>Entidad</b> <br />
              {{ dataPayment.entidad }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <notification-summary-payment
      v-if="showmodal"
      :info-notification="infoNotification"
      @closeModal="showmodal = false"
    />
  </div>
</template>
<script>
import NotificationSummaryPayment from '@/components/Templates/NotificationSummaryPayment'
export default {
  name: 'PaymentEmail',
  components: { NotificationSummaryPayment },
  data() {
    return {
      dataPayment: {},
      infoNotification: null,
      showmodal: false,
    }
  },
  created() {
    let query = this.$route.fullPath.split('?')[1]
      ? this.$route.fullPath.split('?')[1]
      : ''
    if (query != '') {
      this.$store.state.loaderDerek = true
      this.axios({
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/apiBancolombia/response?' + query,
      })
        .then(response => {
          this.dataPayment = response.data
          this.$store.state.loaderDerek = false
          if (response.data.cuponEstrategiaReventa) {
            this.infoNotification = response.data.cuponEstrategiaReventa
            this.showmodal = true
          }
        })
        .catch(() => {
          this.$store.state.loaderDerek = false
          this.$router.push({ name: 'Home' })
        })
    }
    // this.$gtag.pageview(this.$route.fullPath)
  },
}
</script>
